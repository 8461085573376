<template>
  <span>
    <span
      v-for="(part, index) in parts"
      :key="index"
      :data-type="part?.type"
      v-html="part?.value"
      :class="{ 'animated-underline': part?.type === 'number' }"
      :data-active="active ? '' : null"
      data-ignore
    />
  </span>
</template>

<script lang="ts" setup>
const props = defineProps ({
  title: {
    type: String,
    required: true
  },
  active: {
    type: Boolean,
    default: false
  }
})

const chunks = props?.title?.split(/(\d+)/g)

const type = (string: string) => {
  if ( string?.match(/(\d+)/g) ) {
    return 'number'
  }
  else {
    return 'text'
  }
}
const parts = chunks?.map((chunk, index) => {
  return {
    type: type(chunk),
    value: chunk
  }
})
</script>
